<template>
  <div class="password-popover">
    <b-popover :show.sync="showPopover" :target="target" triggers="" placement="top">
      <div class="popover-container">
        <div class="popover__item">
          <i
            class="popover__icon popover__icon-false ion ion-close-circle-outline"
            v-if="!restriction.uppercaseRestriction.complyWithRgx"
          ></i>
          <i
            class="popover__icon popover__icon-true ion ion-checkmark-circle-outline"
            v-if="restriction.uppercaseRestriction.complyWithRgx"
          ></i>
          {{ restriction.uppercaseRestriction.message }}
        </div>
        <div class="popover__item">
          <i
            class="popover__icon popover__icon-false ion ion-close-circle-outline"
            v-if="!restriction.lowecaseRestriction.complyWithRgx"
          ></i>
          <i
            class="popover__icon popover__icon-true ion ion-checkmark-circle-outline"
            v-if="restriction.lowecaseRestriction.complyWithRgx"
          ></i>
          {{ restriction.lowecaseRestriction.message }}
        </div>
        <div class="popover__item">
          <i
            class="popover__icon popover__icon-false ion ion-close-circle-outline"
            v-if="!restriction.numberRestriction.complyWithRgx"
          ></i>
          <i
            class="popover__icon popover__icon-true ion ion-checkmark-circle-outline"
            v-if="restriction.numberRestriction.complyWithRgx"
          ></i>
          {{ restriction.numberRestriction.message }}
        </div>
        <div class="popover__item">
          <i
            class="popover__icon popover__icon-false ion ion-close-circle-outline"
            v-if="!restriction.symbolRestriction.complyWithRgx"
          ></i>
          <i
            class="popover__icon popover__icon-true ion ion-checkmark-circle-outline"
            v-if="restriction.symbolRestriction.complyWithRgx"
          ></i>
          {{ restriction.symbolRestriction.message }}
        </div>
        <div class="popover__item">
          <i
            class="popover__icon popover__icon-false ion ion-close-circle-outline"
            v-if="!restriction.lengthRestriction.complyWithRgx"
          ></i>
          <i
            class="popover__icon popover__icon-true ion ion-checkmark-circle-outline"
            v-if="restriction.lengthRestriction.complyWithRgx"
          ></i>
          {{ restriction.lengthRestriction.message }}
        </div>
      </div>
    </b-popover>
  </div>
</template>

<script>
export default {
  name: "PasswordPopover",
  props: {
    showPopover: Boolean,
    restriction: Object,
    target: String
  },
};
</script>

<style lang="scss" scoped>
::v-deep .popover {
  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__icon {
    margin-right: 5px;
    font-size: 18px;
    &-false {
      color: $color-error;
    }
    &-true {
      color: #00ff00;
    }
  }
}
</style>
