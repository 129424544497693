<template>
  <div class="forgot-password">
    <div class="overlay"></div>
    <div class="forgot-password-content">
      <img
        class="mt-3"
        src="https://shipwithglt.com/wp-content/uploads/2018/09/GLT-LOGISTICS-1.svg"
        alt="GLT LOGO"
      />
      <h2 class="forgot-password-content__title mb-3" v-html="$t('changePassword.title')"></h2>
      <p class="forgot-password-content__information" v-html="$t('changePassword.message')"></p>
      <form action="" class="forgot-password-form">
        <div class="row">
          <div class="col-12">
            <label
              class="form-label text-center forgot-password-form__label"
              v-html="$t('changePassword.newPassword')"
            >
              ></label
            >
            <div class="input-container" id="popover-target-restrictions">
              <input
                @focus="showPopover = true"
                @blur="showPopover = false"
                class="input-container__input"
                :type="typeNewPassword"
                v-model.trim="accountForm.password"
                name="new-password"
                id="new-password"
              />
              <button
                class="input-container__icon-button"
                type="button"
                @click="changeTypeOfInput('new-password')"
              >
                <i :class="iconNewPassword"></i>
              </button>
            </div>
            <PasswordPopover
              class="password-popover"
              :showPopover="showPopover"
              :restriction="restriction"
              :target="'popover-target-restrictions'"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <label
              class="form-label text-center forgot-password-form__label"
              v-html="$t('changePassword.confirmPassword')"
            >
              >
            </label>
            <div class="input-container">
              <input
                class="input-container__input"
                :type="typeConfirmPassword"
                v-model.trim="accountForm.confirm_password"
                name="confirm-password"
                id="confirm-password"
              />
              <button
                class="input-container__icon-button"
                type="button"
                @click="changeTypeOfInput('confirm-password')"
              >
                <i :class="iconConfirmPassword"></i>
              </button>
            </div>
            <span class="message-error" v-show="passwordNotMatch">
              <i class="ion ion-alert-circle-outline"></i>
              Passwords do not match
            </span>
            <span class="message-error" v-show="!passwordNotMatch && passwordIsStrong">
              {{ errorMessage }}
            </span>
          </div>
        </div>
        <div class="button-container">
          <button
            class="btn button-container__button"
            :disabled="loading"
            @click.prevent="changePassword"
            v-html="$t('changePassword.buttonTitle')"
          >
            <b-spinner v-if="loading" variant="primary" label="Spinning"></b-spinner>
          </button>
        </div>
      </form>
    </div>
    <div class="brand-container">
      <span class="brand-container__text">GLT Logistics ® - 2022</span>
    </div>
  </div>
</template>

<script>
import PasswordPopover from "@/components/passwordValidationsPopover.vue";
import changeTypePasswords from "../../Extend/changeTypePasswords";

const { generalMessage } = require("../../utils/ErrorMessages");

export default {
  name: "ForgotPassword",
  components: {
    PasswordPopover,
  },
  mixins: [changeTypePasswords],
  data() {
    return {
      passwordNotMatch: false,
      passwordIsStrong: false,
      passwordError: false,
      confirmPasswordError: false,
      accountForm: {
        password: null,
        confirmPassword: null,
      },
      showPopover: false,
      errorMessage: "",
      loading: false,
    };
  },
  methods: {
    validationsError() {
      if (!this.accountForm.password) {
        this.passwordError = true;
      } else {
        this.passwordError = false;
      }
      if (!this.accountForm.confirm_password) {
        this.confirmPasswordError = true;
      } else {
        this.confirmPasswordError = false;
      }
    },
    async changePassword() {
      this.validationsError();
      this.getErrorMessage();
      if (this.passwordsMatch() && this.strongPassword()) {
        this.loading = true;
        const payload = {
          body: {
            password: this.accountForm.password,
            confirm_password: this.accountForm.confirm_password,
          },
          params: {
            token: this.$route.query.token,
            email: this.$route.query.email,
          },
        };
        const response = await this.$store.dispatch("user/recoveryPassword", payload);
        generalMessage(response.en);
        await this.$router.push({ name: "Login" });
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.forgot-password {
  font-family: $font-family-portal;
  @extend %flex-col-center-center;
  @include background-image(0%, 20%);
  background: url("../../assets/images/home-background.jpg");
}

.overlay {
  @include background-image-overlay($color-white, 0.85);
}

.forgot-password-content {
  @include card-styles;
  z-index: 1;
  color: $color-primary-company;
  img {
    width: 150px;
    height: 150px;
  }
  &__title {
    @include font-main-title;
  }
  &__information {
    width: 60%;
    margin: 0 auto;
    text-align: justify;
  }
}

.forgot-password-form {
  margin-bottom: 4rem;
  margin-top: 2rem;
  &__label {
    font-weight: bold;
  }
  &__btn {
    width: 80%;
    margin: auto;
  }
}
.input-container {
  @include input-information;
  display: flex;
  flex-direction: row;
  width: 60%;
  margin: auto;
  &__input {
    @include input-password;
  }
  &__icon-button {
    @include icon-input;
    display: flex;
    align-items: center;
  }
}

.button-container {
  width: 60%;
  margin: 0px auto;
  margin-top: 1.875rem;
  &__button {
    @include primary-button;
    @include font-button-text;
    background-color: $color-primary-company;
    margin: auto;
    &:hover {
      background-color: $color-primary-company-hover;
    }
  }
}

.message-error {
  color: red;
  font-size: 0.9rem;
}

.brand-container {
  @include brand-quote($color-primary-company);
}
</style>
