export default {
  data() {
    return {
      typeNewPassword: "password",
      typeConfirmPassword: "password",
      iconNewPassword: "ion ion-eye-off-outline",
      iconConfirmPassword: "ion ion-eye-off-outline",
      passwordNotMatch: false,
      passwordIsStrong: false,
      restriction: {
        uppercaseRestriction: {
          complyWithRgx: false,
          message: "Must have at least one uppercase",
        },
        lowecaseRestriction: {
          complyWithRgx: false,
          message: "Must have at least one lowercase",
        },
        numberRestriction: {
          complyWithRgx: false,
          message: "Must have at least one numbers",
        },
        symbolRestriction: {
          complyWithRgx: false,
          message: "Must have at least one symbol",
        },
        lengthRestriction: {
          complyWithRgx: false,
          message: "Must have at least 8 characters",
        },
      },
      password: "",
      confirmPassword: "",
    };
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.password = this.user.password;
        this.confirmPassword = this.user.confirmPassword;
      },
    },
    accountForm: {
      deep: true,
      handler() {
        this.password = this.accountForm.password;
        this.confirmPassword = this.accountForm.confirm_password;
      },
    },
    accountMagayaForm: {
      deep: true,
      handler() {
        this.password = this.accountMagayaForm.magaya_company_password;
        this.confirmPassword = this.accountMagayaForm.confirm_password;
      },
    },
    password() {
      if (/(?=.*?[A-Z])/.test(this.password)) {
        this.restriction.uppercaseRestriction.complyWithRgx = true;
      } else {
        this.restriction.uppercaseRestriction.complyWithRgx = false;
      }
      if (/(?=.*?[0-9])/.test(this.password)) {
        this.restriction.numberRestriction.complyWithRgx = true;
      } else {
        this.restriction.numberRestriction.complyWithRgx = false;
      }
      if (/(?=.*?[a-z])/.test(this.password)) {
        this.restriction.lowecaseRestriction.complyWithRgx = true;
      } else {
        this.restriction.lowecaseRestriction.complyWithRgx = false;
      }
      if (/(?=.*?[#?!@$%^&*-])/.test(this.password)) {
        this.restriction.symbolRestriction.complyWithRgx = true;
      } else {
        this.restriction.symbolRestriction.complyWithRgx = false;
      }
      if (/.{8,}/.test(this.password)) {
        this.restriction.lengthRestriction.complyWithRgx = true;
      } else {
        this.restriction.lengthRestriction.complyWithRgx = false;
      }
    },
  },
  methods: {
    changeTypeOfInput(id) {
      const inputId = document.getElementById(id);
      if (inputId.id === "new-password") {
        if (this.typeNewPassword === "password") {
          this.typeNewPassword = "text";
          this.iconNewPassword = "ion ion-eye-outline";
        } else {
          this.typeNewPassword = "password";
          this.iconNewPassword = "ion ion-eye-off-outline";
        }
      } else if (inputId.name === "confirm-password") {
        if (this.typeConfirmPassword === "password") {
          this.typeConfirmPassword = "text";
          this.iconConfirmPassword = "ion ion-eye-outline";
        } else {
          this.typeConfirmPassword = "password";
          this.iconConfirmPassword = "ion ion-eye-off-outline";
        }
      }
    },
    passwordsMatch() {
      if (this.password !== this.confirmPassword) {
        this.passwordNotMatch = true;
        return false;
      }
      this.passwordNotMatch = false;
      return true;
    },
    strongPassword() {
      // eslint-disable-next-line max-len
      if (/^(?=.*\d)(?=.*?[#?!@$%^&*-])(?=.*[a-z])(?=.*[A-Z]).{8,256}$/.test(this.password)) {
        this.passwordIsStrong = false;
        return true;
      }
      this.passwordIsStrong = true;
      return false;
    },
    getErrorMessage() {
      const restrictions = [];
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const property in this.restriction) {
        if (!this.restriction[property].complyWithRgx) {
          restrictions.push(property);
        }
      }
      if (restrictions.length === 0) {
        this.errorMessage = "";
        return;
      }
      this.errorMessage = this.restriction[restrictions[0]].message;
    }
  },
};
